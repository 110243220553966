import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

export default function MainDialog({ title, cardBody, isDialogOpen, setIsDialogOpen, submitHandler }) {
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const iconContainerStyles = {
        cursor: "pointer",
        width: "40px",
        height: "40px",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isHovered ? "lightgray" : "white",
        transition: "background-color 0.3s",
    };

    const iconStyles = {
        color: isHovered ? "black" : "#73716b",
        transition: "color 0.3s",
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={isDialogOpen}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", }}>
                        <p style={{ paddingLeft: "24px", fontSize: "20px", fontWeight: "500" }}>{title ? title : null}</p>
                        <div
                            style={iconContainerStyles}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <CloseIcon sx={iconStyles} onClick={handleClose} />
                        </div>
                    </div>
                </div>
                <DialogContent fullScreen={fullScreen}>
                    {cardBody}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={submitHandler} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
