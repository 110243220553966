import React, { useState } from 'react';
import "./css/requestForm.css"
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useInterestedToOpenAccountMutation } from '../../../store/apis/supportAPI';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { interestedToOpenAccountSchema } from '../../../Validations/contactFormValidations';
import { Link } from 'react-router-dom';

const RequestForm = () => {
    const [accountType, setAccountType] = useState("donor-account")
    const [interestedToOpenAccount] = useInterestedToOpenAccountMutation();
    //handlers
    const onSubmit = async ({ firstName, surname, charityName, phoneNumber, email }, actions) => {
        const data = {
            accountType, firstName, surname, charityName, phoneNumber, email
        }
        const res = await interestedToOpenAccount(data);
        if (res.error) {
            toast.error("Faild to send: " + res.error.data.message);
        }
        toast.success("We will get in touch with you shortly to complete the account opening process.")
        actions.resetForm();
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            accountType: "",
            firstName: "",
            surname: "",
            charityName: "",
            phoneNumber: "",
            email: "",
        },
        validationSchema: interestedToOpenAccountSchema,
        onSubmit,
    });
    return (
        <section className='req-form-container'>
            <div className='req-form-wrapper'>
                <div className='req-form-left-content'>
                    <div>
                        <h1>Open Your A.C.T. Account Today </h1>
                        <h4>To open your A.C.T. account, please follow these steps:</h4>
                    </div>
                    <div className='req-form-sub-wrapper'>

                        <p><span>1. Select Account Type:</span> Choose the type of account you need.</p>
                        <div>
                            <p><span>2. Complete Online Form:</span> Our team will send you an email with a link to an online form.</p>
                            <p>Fill it out to proceed.</p>
                        </div>
                        <p><span>3. Receive Account Number:</span> After submitting the form, you will receive another
                            email with your A.C.T. account number.</p>
                        <div>
                            <span>4. Start Raising funds !</span>
                            <p>For any questions or assistance, please contact us via phone or email. We are here
                                to help and make the process as smooth as possible.</p>
                        </div>
                    </div>
                    <h2>We are here for your success - open your A.C.T. account today.</h2>
                    <Link to="/login" style={{
                        background: "#d9d9df", color: "#3C76D2", textDecoration: "none", paddingLeft: "12px", paddingRight: "12px", paddingTop: "8px", paddingBottom: "8px", borderRadius: "8px", fontWeight: "600", '&:hover': {
                            backgroundColor: '#d9d9e2'
                        },
                    }}>Have An Account</Link>
                </div>
                <div className='req-form-right-content'>
                    <form onSubmit={handleSubmit} autoComplete="false">
                        <FormControl>
                            <FormLabel id="accountType">I WOULD LIKE TO OPEN A...</FormLabel>
                            <RadioGroup
                                aria-labelledby="accountType"
                                defaultValue="Donor account"
                                name="accountType"
                                id='accountTypes'
                                // value={values.accountType}
                                // helperText={errors.accountType && touched.accountType ? errors.accountType : ""}
                                onBlur={handleBlur}
                                onChange={(event) => setAccountType(event.target.value)}
                            >
                                <FormControlLabel value="Donor account" control={<Radio />} label="Donor account" />
                                <FormControlLabel value="Fundraiser account" control={<Radio />} label="Fundraiser account" />
                                <FormControlLabel value="Distributor account" control={<Radio />} label="Distributor account" />
                                <FormControlLabel value="Recipient account - Individual" control={<Radio />} label="Recipient account - Individual" />
                                <FormControlLabel value="Recipient account - Registered Charity" control={<Radio />} label="Recipient account - Registered Charity" />
                                <FormControlLabel value="I am not sure" control={<Radio />} label="I am not sure" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            required
                            error={!!errors.firstName && touched.firstName}
                            id="firstName"
                            label="First Name"
                            variant="outlined"
                            type="text"
                            value={values.firstName}
                            helperText={errors.firstName && touched.firstName ? errors.firstName : ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "100%",
                                    maxWidth: '100%',
                                },
                            }}
                        />
                        <TextField
                            required
                            error={!!errors.surname && touched.surname}
                            id="surname"
                            label="Surname"
                            variant="outlined"
                            type="text"
                            value={values.surname}
                            helperText={errors.surname && touched.surname ? errors.surname : ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "100%",
                                    maxWidth: '100%',
                                },
                            }}
                        />

                        {
                            accountType === "Recipient account - Registered Charity" ? <TextField
                                required
                                error={!!errors?.charityName && touched?.charityName}
                                id="charityName"
                                label="Name of Charity"
                                variant="outlined"
                                type="text"
                                value={values?.charityName}
                                helperText={errors?.charityName && touched?.charityName ? errors?.charityName : ""}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                sx={{
                                    width: {
                                        xs: "100%",
                                        sm: "100%",
                                        md: "100%",
                                        maxWidth: '100%',
                                    },
                                }}
                            /> : null
                        }

                        <TextField
                            required
                            error={!!errors.phoneNumber && touched.phoneNumber}
                            id="phoneNumber"
                            label="Mobile Number"
                            variant="outlined"
                            type="text"
                            value={values.phoneNumber}
                            helperText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "100%",
                                    maxWidth: '100%',
                                },
                            }}
                        />
                        <TextField
                            required
                            error={!!errors.email && touched.email}
                            id="email"
                            label="Email Address"
                            variant="outlined"
                            type="text"
                            value={values.email}
                            helperText={errors.email && touched.email ? errors.email : ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "100%",
                                    maxWidth: '100%',
                                },
                            }}
                        />
                        <Button sx={{ color: "#0da6ab !importent" }} type="submit" variant="contained" size="large" >Submit</Button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default RequestForm;