import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFetchClientsBalanceQuery } from "../../../store";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Loader from "../../shared/Loader";
import "./css/clientsDetails.css";

export default function ClientsDetails() {
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);

  const payload = useMemo(() => ({ clientID: client?.ID, token }), [client?.ID, token]);
  const { data: balance, isError, error, isLoading } = useFetchClientsBalanceQuery(payload);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-message">Error: {error.message}</div>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        "& > :not(style)": {
          m: 1,
          width: { xs: "100%", sm: "48%", md: "32%" },
          height: "auto",
        },
      }}
    >
      <Paper sx={{ padding: 3 }}>
        <div className="client-info">
          <span className="label">A.C.T Account ID:</span> {client?.ID}
        </div>
        <div className="client-info">
          <span className="label">Name:</span> {client?.FullName} {client?.CompanyName && ` - ${client.CompanyName}`}
        </div>
      </Paper>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <span className="label">Your Account Balance:</span> £{balance}
      </Paper>
    </Box>
  );
}
