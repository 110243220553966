import React, { useState, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useUpdateClientMutation, useFetchUsersClientsQuery, useResetPasswordMutation } from "../../../store";
import "./css/userinfo.css";
import Loader from "../../shared/Loader";
import { resetPasswordSchema } from "../../../Validations/resetPasswordValidations";

const UserInfo = () => {
  const [showPassword, setShowPassword] = useState(false);
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const { ID, FullName, MobileUK, DefaultPayment } = client || {};
  const token = useSelector((state) => state.user.token);
  const balance = useSelector((state) => state.user.balance);

  const [updateClient, { isLoading }] = useUpdateClientMutation();
  const { data } = useFetchUsersClientsQuery(token);
  const [resetPassword, { isLoading: resetPasswordLoading }] = useResetPasswordMutation();

  const handlePaymentMethodChange = useCallback(async (e) => {
    e.preventDefault();
    const res = await updateClient({ clientID: ID, field: "DefaultPayment", value: e.target.value, token });
    if (res?.data) {
      toast.success(res.data.message);
    } else {
      toast.error(res.error.data.message);
    }
  }, [ID, token, updateClient]);

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);
  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      try {
        await resetPassword({ token, newPassword: values.newPassword });
        toast.success("Password has been reset successfully.");
        resetForm();
      } catch (error) {
        toast.error("Error resetting password.");
      }
    },
  });

  const renderTopUpOptions = useMemo(() => {
    if (client?.CardStatus === "Active" || client?.CardStatus === "Card Ordered") {
      return (
        <>
          <option value="Bank">Bank Pay out</option>
          <option value="P.P. Card">Prepaid Card Top-Up</option>
        </>
      );
    }
    return <option value="Bank">Bank Pay out</option>;
  }, [client]);

  if (isLoading || resetPasswordLoading) {
    return <Loader />;
  }

  return (
    <div className="user-container">
      <h1 className="user-header">My Account Details</h1>
      <div className="user-detail">
        <span className="user-label">A.C.T Account ID:</span>
        <span className="user-value">{ID}</span>
      </div>
      <div className="user-detail">
        <span className="user-label">Name:</span>
        <span className="user-value">{FullName}</span>
      </div>
      <div className="user-detail">
        <span className="user-label">Mobile:</span>
        <span className="user-value">{MobileUK}</span>
      </div>
      <div className="user-detail">
        <span className="user-label">Default Payment method:</span>
        <select className="user-value" value={DefaultPayment || ""} onChange={handlePaymentMethodChange}>
          <option value="" disabled>
            Please Select
          </option>
          {renderTopUpOptions}
        </select>
      </div>
      <div className="user-detail">
        <span className="user-label">Your Account Balance:</span>
        <span className="user-value user-negativeBalance">£ {balance}</span>
      </div>
      <div className="user-detail">
        <span className="user-label">Change Password:</span>
        <form onSubmit={handleSubmit} className="change-password-form">
          <TextField
            required
            error={!!errors.newPassword && touched.newPassword}
            label="New Password"
            type={showPassword ? "text" : "password"}
            id="newPassword"
            name="newPassword"
            value={values.newPassword}
            helperText={errors.newPassword && touched.newPassword ? errors.newPassword : ""}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            error={!!errors.confirmPassword && touched.confirmPassword}
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            value={values.confirmPassword}
            helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <button type="submit" className="change-password-button">Change Password</button>
        </form>
      </div>
    </div>
  );
};

export default UserInfo;

