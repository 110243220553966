import React, { useState, useMemo, useCallback } from "react";
import { useFetchClientByIDQuery, useInternalTransferMutation } from "../../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../shared/Loader";
import styles from "./css/transfarFunds.module.css";
import debounce from "lodash.debounce";

const TransfarFundsComponent = () => {
  const [id, setId] = useState("");
  const [toID, setToID] = useState(null);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const balance = useSelector((state) => state.user.balance);

  const payload = useMemo(() => ({ clientID: client?.ID, token }), [client, token]);

  const {
    data: fetchedClient,
    error: errorFetchingClient,
    isLoading: fetchingClient,
  } = useFetchClientByIDQuery({ id: toID, token }, { skip: !toID });

  const [internalTransfer] = useInternalTransferMutation();

  const handleIdBlur = useCallback(
    debounce((id) => {
      setToID(id);
    }, 300),
    []
  );

  const handleIdChange = (e) => {
    setToID(null);
    setId(e.target.value);
    handleIdBlur(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!toID) {
      toast.error("Recipient ID is required");
      return;
    }
    if (amount <= 0 || amount > balance) {
      toast.error("Invalid amount");
      return;
    }
    setIsSubmitting(true);
    const res = await internalTransfer({ toID, amount, note, ...payload });
    setIsSubmitting(false);
    if (res?.data) {
      toast.success(res.data.message);
      setAmount("");
      setNote("");
      setId("");
      setToID(null);
    } else {
      toast.error(res.error.data.message);
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Transfer Funds</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="id" className={styles.label}>
            Please enter the A.C.T Account ID
          </label>
          <input
            type="number"
            id="id"
            value={id}
            onChange={handleIdChange}
            className={styles.input}
          />
        </div>
        {errorFetchingClient && (
          <div className={styles.error}>{errorFetchingClient.data.message}</div>
        )}
        {fetchedClient && toID && (
          <div className={styles.success}>{fetchedClient.message}</div>
        )}
        <div className={styles.formGroup}>
          <label htmlFor="amount" className={styles.label}>
            Amount
          </label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="note" className={styles.label}>
            Note
          </label>
          <input
            type="text"
            id="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className={styles.input}
          />
        </div>
        <button
          type="submit"
          className={styles.submitButton}
        >
          Submit
        </button>
      </form>
      <div className={styles.balance}>
        <p>Current Balance: £ {balance}</p>
      </div>
    </div>
  );
};

export default TransfarFundsComponent;

