/* eslint-disable no-undef */
import React from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import "./css/Contact.css";
import { useContactWithSupportMutation } from "../../../store/apis/supportAPI";
import { supportMessageSchema } from "../../../Validations/contactFormValidations.js";
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { toast } from "react-toastify";

const Contact = ({ contactRef }) => {
  const [contactWithSupport, ContactWithSupportResult] = useContactWithSupportMutation();

  //handlers
  const onSubmit = async ({ name, email, phoneNumber, message }, actions) => {
    const data = {
      name,
      email,
      phoneNumber,
      message,
    };
    const res = await contactWithSupport(data);
    if (res.error) {
      toast.error("Faild to send: " + res.error.data.message);
    }
    actions.resetForm();
    toast.success("We received your email. We will get in touch with you soon.")
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: supportMessageSchema,
    onSubmit,
  });
  if (ContactWithSupportResult.isSuccess) {
    return <div>Please check your email to complete the registration proccess</div>;
  }

  return (
    <section className='contact-form-container'>
      <div className='contact-form-wrapper'>
        <div className='contact-form-left-content'>
          <div>
            <Typography variant="h4" gutterBottom>
              Contact Information
            </Typography>
            <h4>Get in touch with us, our team is ready to assist you.</h4>
          </div>
          <div className="contact-address-container">
            <div>
              <div className="contact-info-container">
                <div className="contact-icon-container">
                  <PhoneIcon />
                </div>
                <p>+0333 613 4528</p>
              </div>
              <h2>Main Office</h2>
              <div>
                <p>Suite 1, 35 Northumberland St.</p>
                <p>Salford M7 4DR</p>
              </div>
            </div>
            <div>
              <div className="contact-info-container">
                <div className="contact-icon-container">
                  <MailIcon />
                </div>
                <p>office@actmcr.org.uk</p>
              </div>
              <h2>Opening Hours</h2>
              <div>
                <p>Sunday: 12:00 - 16:00</p>
                <p>Mon - Thurs: 11:00 - 16:30</p>
                <p>Friday - Thurs: 10:30 - 12:00</p>
              </div>
            </div>
          </div>
        </div>
        <div className='contact-form-right-content'>
          <form className="contact-form" onSubmit={handleSubmit} autoComplete="false">
            <TextField
              required
              error={!!errors.name && touched.name}
              id="name"
              label="Your Name"
              variant="outlined"
              type="text"
              value={values.name}
              helperText={errors.name && touched.name ? errors.name : ""}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                width: {
                  maxWidth: "100%",
                },
              }}
            />
            <TextField
              required
              error={!!errors.email && touched.email}
              id="email"
              label="Your Email"
              variant="outlined"
              type="email"
              value={values.email}
              helperText={errors.email && touched.email ? errors.email : ""}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                maxWidth: "100%",
              }}
            />
            <TextField
              required
              error={!!errors.phoneNumber && touched.phoneNumber}
              id="phoneNumber"
              label="Phone Number"
              variant="outlined"
              type="text"
              value={values.phoneNumber}
              helperText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ""}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                maxWidth: "100%",
              }}
            />
            <TextField
              required
              error={!!errors.message && touched.message}
              variant="outlined"
              type="text"
              value={values.message}
              helperText={errors.message && touched.message ? errors.message : ""}
              onBlur={handleBlur}
              onChange={handleChange}
              id="message"
              label="Message"
              multiline
              rows={4}
              sx={{
                maxWidth: "100%",
                color: "white",
              }}
            />
            <Button type="submit" variant="contained" size="large">
              SEND US A MESSAGE
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
