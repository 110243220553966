import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setBalance } from "../";

const transactionsApi = createApi({
  reducerPath: "transactions",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1",
  }),
  tagTypes: ["balance"],
  endpoints(builder) {
    return {
      fetchClientsTransactions: builder.query({
        query: (payload) => {
          return {
            url: `/transactions?clientID=${payload.clientID}`,
            method: "GET",
            headers: { Authorization: `Bearer ${payload.token}` },
          };
        },
      }),
      fetchClientsBalance: builder.query({
        query: (payload) => {
          return {
            url: `/transactions/balance?clientID=${payload.clientID}`,
            method: "GET",
            headers: { Authorization: `Bearer ${payload.token}` },
          };
        },
        providesTags: ["balance"],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          dispatch(setBalance(parseFloat(data)));
        },
      }),
      internalTransfer: builder.mutation({
        query: (payload) => {
          const { token, ...body } = payload;
          return {
            url: "/transactions/transfer",
            method: "POST",
            body,
            headers: { Authorization: `Bearer ${token}` },
          };
        },
        invalidatesTags: ["balance"],
      }),
      sendInvoiceMail: builder.mutation({
        query: (payload) => {
          const { token, clientID, IDTransactions, formData, invoiceType } = payload;
          return {
            url: `/transactions/invoice-mail/?IDTransactions=${IDTransactions}&clientID=${clientID}&invoiceType=${invoiceType}`,
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${token}` },
          };
        },
        invalidatesTags: ["balance"],
      }),
      requestTopup: builder.mutation({
        query: (payload) => {
          const { token, ...body } = payload;
          return {
            url: "/transactions",
            method: "POST",
            body,
            headers: { Authorization: `Bearer ${token}` },
          };
        },
        invalidatesTags: ["balance"],
      }),
      roshCoilelTopup: builder.mutation({
        query: (payload) => {
          const { token, ...body } = payload;
          return {
            url: "/transactions/roshCoilelTopup",
            method: "POST",
            body,
            headers: { Authorization: `Bearer ${token}` },
          };
        },
        invalidatesTags: ["balance"],
      }),
    };
  },
});

export const {
  useFetchClientsTransactionsQuery,
  useFetchClientsBalanceQuery,
  useInternalTransferMutation,
  useRequestTopupMutation,
  useRoshCoilelTopupMutation,
  useSendInvoiceMailMutation
} = transactionsApi;
export { transactionsApi };
