import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Menu } from "@mui/material";

import logo from "../../assets/logo.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import useHeader from "../../hooks/useHeader";
import { persistor, successLogout } from "../../store";


export default function MenuAppBar() {
  const isAuth = useSelector((state) => state.user.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState({});
  const { translate } = useHeader();

  const handleMenu = (event, index, isSubMenu) => {
    // Use a different key for submenus
    const key = isSubMenu ? `submenu_${index}` : index;
    setAnchorEl({ ...anchorEl, [key]: event.currentTarget });
  };

  const handleClose = (index, isSubMenu) => {
    const key = isSubMenu ? `submenu_${index}` : index;
    setAnchorEl({ ...anchorEl, [key]: null });
  };

  const handleLogout = async () => {
    persistor.purge();
    dispatch(successLogout());
    navigate("/");
  };

  const menuItems = [
    { label: "Home", path: "home" },
    { label: "Open an account", path: "contact-form" },
    { label: "contact us", path: "contact-us" },
  ];


  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1500,
        display: "flex",
        alignItems: "center",
        transition: "all 300ms ease-in-out",
        translate: `0 ${translate}px`,
      }}
    >
      <AppBar position="static" sx={{ backgroundColor: "#263773", color: "#0da6ab" }}>
        <div>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "40px",
                  backgroundColor: "white",
                  marginRight: 5,
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              />
              ACT Giving made easy
            </Typography>
            {/* Hamburger Menu for mobile and tablet view */}
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: "block", md: "none" } }}
              onClick={(event) => handleMenu(event, "menu")}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-list-menu"
              anchorEl={anchorEl["menu"]}
              open={Boolean(anchorEl["menu"])}
              onClose={() => handleClose("menu")}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              sx={{ zIndex: "100000000", width: "600px" }}
            >
              {/* Render menu items in the hamburger menu */}
              {menuItems.map((menuItem, index) => (
                <div key={index} style={{ marginLeft: 5 }}>
                  <Button
                    variant="text"
                    sx={{ color: "#0da6ab" }}
                    aria-controls={"menu-list-" + index}
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleMenu(event, index);
                      navigate("/" + menuItem.path);
                      handleClose("menu");
                    }}
                  >
                    {menuItem.label}
                  </Button>
                </div>
              ))}
              {isAuth ? (
                <>
                  <Button
                    variant="text"
                    sx={{ color: "#0da6ab", marginLeft: "6px" }}
                    aria-controls={"menu-list-"}
                    aria-haspopup="true"
                    onClick={() => {
                      navigate("/account");
                      handleClose("menu");
                    }}
                  >
                    My account
                  </Button>
                  <div>
                    <Button
                      variant="text"
                      sx={{ color: "#0da6ab", marginLeft: "6px" }}
                      aria-controls={"menu-list-"}
                      aria-haspopup="true"
                      onClick={() => {
                        handleLogout();
                        handleClose("menu");
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  variant="text"
                  sx={{ color: "#0da6ab", marginLeft: "6px" }}
                  aria-controls={"menu-list-"}
                  aria-haspopup="true"
                  onClick={() => {
                    navigate("/login");
                    handleClose("menu");
                  }}
                >
                  Signup / Login
                </Button>
              )}
            </Menu>
            {/* Render menu items in desktop view */}
            <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
              {menuItems.map((menuItem, index) => (
                <div key={index} style={{ marginLeft: 5 }}>
                  <Button
                    variant="text"
                    sx={{ color: "#0da6ab" }}
                    aria-controls={"menu-list-" + index}
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleMenu(event, index);
                      navigate("/" + menuItem.path);
                    }}
                  >
                    {menuItem.label}
                  </Button>
                </div>
              ))}
              {isAuth ? (
                <>
                  <Button
                    variant="text"
                    sx={{ color: "#0da6ab", paddingLeft: "8px" }}
                    aria-controls={"menu-list-"}
                    aria-haspopup="true"
                    onClick={() => navigate("/account")}
                  >
                    My account
                  </Button>
                  <Button
                    variant="text"
                    sx={{ color: "#fff" }}
                    aria-controls={"menu-list-"}
                    aria-haspopup="true"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <Button
                  variant="text"
                  sx={{ color: "#fff" }}
                  aria-controls={"menu-list-"}
                  aria-haspopup="true"
                  onClick={() => navigate("/login")}
                >
                  Signup / Login
                </Button>
              )}
            </Box>
          </Toolbar>
        </div>
      </AppBar>
    </Box>

    // <Box
    //   sx={{ flexGrow: 1 }}
    //   style={{
    //     position: "sticky",
    //     top: 0,
    //     zIndex: 1500,
    //     display: "flex",
    //     alignItems: "center",
    //     transition: "all 300ms ease-in-out",
    //     translate: `0 ${translate}px`,
    //   }}
    // >
    //   <AppBar position="static" sx={{ backgroundColor: "#263773", color: "#0da6ab" }}>
    //     <div>
    //       <Toolbar>
    //         <Typography variant="h6" component="div" sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
    //           <img
    //             src={logo}
    //             alt="logo"
    //             style={{
    //               height: "40px",
    //               backgroundColor: "white",
    //               marginRight: 5,
    //               borderRadius: "5px",
    //               cursor: "pointer",
    //             }}
    //             onClick={() => navigate("/")}
    //           />
    //           ACT Giving made easy
    //         </Typography>
    //         {/* Hamburger Menu for mobile and tablet view */}
    //         <IconButton
    //           size="large"
    //           edge="start"
    //           color="inherit"
    //           aria-label="menu"
    //           sx={{ display: { xs: "block", md: "none" } }} // Changed sm to md
    //           onClick={(event) => handleMenu(event, "menu")}
    //         >
    //           <MenuIcon />
    //         </IconButton>
    //         <Menu
    //           id="menu-list-menu"
    //           anchorEl={anchorEl["menu"]}
    //           open={Boolean(anchorEl["menu"])}
    //           onClose={() => handleClose("menu")}
    //           anchorOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           transformOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           keepMounted
    //           sx={{ zIndex: "100000000" }}
    //         >
    //           {/* Render menu items in the hamburger menu */}
    //           {menuItems.map((menuItem, index) => (
    //             <div key={index} style={{ marginLeft: 5 }}>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab" }}
    //                 aria-controls={"menu-list-" + index}
    //                 aria-haspopup="true"
    //                 onClick={(event) => {
    //                   handleMenu(event, index);
    //                   navigate("/" + menuItem.path);
    //                 }}
    //               >
    //                 {menuItem.label}
    //               </Button>
    //             </div>
    //           ))}
    //           {isAuth ? (
    //             <>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab", marginLeft: "8px" }}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={() => navigate("/account")}
    //               >
    //                 My account
    //               </Button>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#fff" }}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={handleLogout}
    //               >
    //                 Logout
    //               </Button>
    //             </>
    //           ) : (
    //             <Button
    //               variant="text"
    //               sx={{ color: "#fff" }}
    //               aria-controls={"menu-list-"}
    //               aria-haspopup="true"
    //               onClick={() => navigate("/login")}
    //             >
    //               Signup / Login
    //             </Button>
    //           )}
    //         </Menu>
    //         {/* Render menu items in desktop view */}
    //         <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}> {/* Changed sm to md */}
    //           {menuItems.map((menuItem, index) => (
    //             <div key={index} style={{ marginLeft: 5 }}>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab" }}
    //                 aria-controls={"menu-list-" + index}
    //                 aria-haspopup="true"
    //                 onClick={(event) => {
    //                   handleMenu(event, index);
    //                   navigate("/" + menuItem.path);
    //                 }}
    //               >
    //                 {menuItem.label}
    //               </Button>
    //             </div>
    //           ))}
    //           {isAuth ? (
    //             <>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab", paddingLeft: "8px" }}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={() => navigate("/account")}
    //               >
    //                 My account
    //               </Button>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#fff" }}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={handleLogout}
    //               >
    //                 Logout
    //               </Button>
    //             </>
    //           ) : (
    //             <Button
    //               variant="text"
    //               sx={{ color: "#fff" }}
    //               aria-controls={"menu-list-"}
    //               aria-haspopup="true"
    //               onClick={() => navigate("/login")}
    //             >
    //               Signup / Login
    //             </Button>
    //           )}
    //         </Box>
    //       </Toolbar>
    //     </div>
    //   </AppBar>
    // </Box>

    // <Box
    //   sx={{ flexGrow: 1 }}
    //   style={{
    //     position: "sticky",
    //     top: 0,
    //     zIndex: 1500,
    //     display: "flex",
    //     alignItems: "center",
    //     transition: "all 300ms ease-in-out",
    //     translate: `0 ${translate}px`,
    //   }}
    // >
    //   <AppBar position="static" sx={{ backgroundColor: "#263773", color: "#0da6ab" }}>
    //     <div>
    //       <Toolbar>
    //         <Typography variant="h6" component="div" sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
    //           <img
    //             src={logo}
    //             alt="logo"
    //             style={{
    //               height: "40px",
    //               backgroundColor: "white",
    //               marginRight: 5,
    //               borderRadius: "5px",
    //               cursor: "pointer",
    //             }}
    //             onClick={() => navigate("/")}
    //           />
    //           ACT Giving made easy
    //         </Typography>
    //         {/* Hamburger Menu for mobile view */}
    //         <IconButton
    //           size="large"
    //           edge="start"
    //           color="inherit"
    //           aria-label="menu"
    //           sx={{ display: { xs: "block", sm: "none" } }}
    //           onClick={(event) => handleMenu(event, "menu")}
    //         >
    //           <MenuIcon />
    //         </IconButton>
    //         <Menu
    //           id="menu-list-menu"
    //           anchorEl={anchorEl["menu"]}
    //           open={Boolean(anchorEl["menu"])}
    //           onClose={() => handleClose("menu")}
    //           anchorOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           transformOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           keepMounted
    //           sx={{ zIndex: "100000000" }}

    //         >
    //           {/* Render menu items in the hamburger menu */}
    //           {menuItems.map((menuItem, index) => (
    //             <div key={index} style={{ marginLeft: 5 }}>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab" }}
    //                 aria-controls={"menu-list-" + index}
    //                 aria-haspopup="true"
    //                 onClick={(event) => {
    //                   handleMenu(event, index);
    //                   navigate("/" + menuItem.path);
    //                 }}
    //               >
    //                 {menuItem.label}
    //               </Button>
    //             </div>
    //           ))}
    //           {isAuth ? (
    //             <>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab", marginLeft: "8px"}}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={() => navigate("/account")}
    //               >
    //                 My account
    //               </Button>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#fff" }}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={handleLogout}
    //               >
    //                 Logout
    //               </Button>
    //             </>
    //           ) : (
    //             <Button
    //               variant="text"
    //               sx={{ color: "#fff" }}
    //               aria-controls={"menu-list-"}
    //               aria-haspopup="true"
    //               onClick={() => navigate("/login")}
    //             >
    //               Signup / Login
    //             </Button>
    //           )}
    //         </Menu>
    //         {/* Render menu items in desktop view */}
    //         <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}>
    //           {menuItems.map((menuItem, index) => (
    //             <div key={index} style={{ marginLeft: 5 }}>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab" }}
    //                 aria-controls={"menu-list-" + index}
    //                 aria-haspopup="true"
    //                 onClick={(event) => {
    //                   handleMenu(event, index);
    //                   navigate("/" + menuItem.path);
    //                 }}
    //               >
    //                 {menuItem.label}
    //               </Button>
    //             </div>
    //           ))}
    //           {isAuth ? (
    //             <>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#0da6ab",  paddingLeft: "8px" }}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={() => navigate("/account")}
    //               >
    //                 My account
    //               </Button>
    //               <Button
    //                 variant="text"
    //                 sx={{ color: "#fff" }}
    //                 aria-controls={"menu-list-"}
    //                 aria-haspopup="true"
    //                 onClick={handleLogout}
    //               >
    //                 Logout
    //               </Button>
    //             </>
    //           ) : (
    //             <Button
    //               variant="text"
    //               sx={{ color: "#fff" }}
    //               aria-controls={"menu-list-"}
    //               aria-haspopup="true"
    //               onClick={() => navigate("/login")}
    //             >
    //               Signup / Login
    //             </Button>
    //           )}
    //         </Box>
    //       </Toolbar>
    //     </div>
    //   </AppBar>
    // </Box>
  );
}
