import { useState, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import { TextField, IconButton, Button, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";

import { sinupSchema } from "../../../Validations/SignupValidations";
import { useSignupByPasswordMutation } from "../../../store";
import GoogleLoginBtn from "../../button/GoogleLoginBtn";
import styles from "./css/LoginForm.module.css"

function SignupForm() {
  const [SignupByPassword, SignupByPasswordResult] = useSignupByPasswordMutation();

  // Handlers
  const onSubmit = useCallback(async ({ email, password }, actions) => {
    const res = await SignupByPassword({ email, password });
    if (res.error) {
      toast.error("Failed to signup: " + res.error.data.message);
    } else {
      toast.success("Please check your email to complete the registration process");
    }
    actions.resetForm();
  }, [SignupByPassword]);

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);
  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: sinupSchema,
    onSubmit,
  });

  const [showPassword, setShowPassword] = useState(false);

  // Memoized Elements
  const emailField = useMemo(() => (
    <TextField
      required
      error={!!errors.email && touched.email}
      label="Email"
      type="email"
      id="email"
      value={values.email}
      helperText={errors.email && touched.email ? errors.email : ""}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  ), [errors.email, touched.email, values.email, handleBlur, handleChange]);

  const passwordField = useMemo(() => (
    <TextField
      required
      error={!!errors.password && touched.password}
      label="Password"
      type={showPassword ? "text" : "password"}
      id="password"
      value={values.password}
      helperText={errors.password && touched.password ? errors.password : ""}
      onBlur={handleBlur}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ), [errors.password, touched.password, values.password, showPassword, handleClickShowPassword, handleMouseDownPassword, handleBlur, handleChange]);

  const confirmPasswordField = useMemo(() => (
    <TextField
      required
      error={!!errors.confirmPassword && touched.confirmPassword}
      label="Please confirm Password"
      type={showPassword ? "text" : "password"}
      id="confirmPassword"
      value={values.confirmPassword}
      helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
      onBlur={handleBlur}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ), [errors.confirmPassword, touched.confirmPassword, values.confirmPassword, showPassword, handleClickShowPassword, handleMouseDownPassword, handleBlur, handleChange]);

  if (SignupByPasswordResult.isSuccess) {
    return <div>Please check your email to complete the registration process</div>;
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit} autoComplete="off">
      {emailField}
      {passwordField}
      {confirmPasswordField}
      <Button variant="contained" type="submit" disabled={isSubmitting}>
        Submit
      </Button>
      <GoogleLoginBtn />
    </form>
  );
}

export default SignupForm;
