// src/components/DocumentPage.js
import React, { useState } from 'react';
import { Container, Grid, Typography, Paper } from '@mui/material';
import './css/myDocuments.css';
import FileUploader from '../components/features/MyDocuments/FileUploader';
import DocumentViewer from '../components/features/MyDocuments/DocumentViewer';
import { useSelector } from 'react-redux';
import { useUploadDocMutation } from '../store/apis/documentApi';
import { toast } from 'react-toastify';
import DocumentList from '../components/features/MyDocuments/DocumentList';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';



const MyDocuments = () => {
    const [file, setFile] = useState(null);
    const [permission, setPermission] = useState("Public");
    const token = useSelector((state) => state.user.token);

    const [uploadDoc] = useUploadDocMutation()

    const handleFileUpload = (uploadedFile) => {
        setFile(uploadedFile);
    };

    const handleStoreFile = async () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('permission', permission);

            try {
                await uploadDoc({ fileData: formData, token, clientID: 5543 })
                toast.success("File uploaded successfully.")
                setFile(null)
            } catch (err) {
                toast.error("There was an server side error!")
            }
        } else {
            console.error('No file selected for upload.');
            toast.error("No file selected for upload!")
        }
    };

    const handleChange = (event) => {
        setPermission(event.target.value);
    };

    const handlePdfTest = () => {
        fetch("http://localhost:5000/api/v1/support/pdf-test", {
            method: "POST"
        })
            .then(res => res.json())
            .then(data => console.log(data))
    }

    return (
        <Container maxWidth="md" className="document-page-container">
            <Typography variant="h4" className="page-title">Document Management</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className="paper-container">
                        <FileUploader onFileUpload={handleFileUpload} handleStoreFile={handleStoreFile} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Permission</FormLabel>
                        <RadioGroup
                            aria-label="permission"
                            name="permission"
                            value={permission}
                            onChange={handleChange}
                            row
                        >
                            <FormControlLabel value="Public" control={<Radio />} label="Public" />
                            <FormControlLabel value="Owner" control={<Radio />} label="Owner" />
                            <FormControlLabel value="Office only" control={<Radio />} label="Office only" />
                        </RadioGroup>
                    </FormControl>
                    {/* <button onClick={handlePdfTest}>Click pdf</button> */}
                </Grid>
                <Grid item xs={12}>
                    <Paper className="paper-container">
                        <DocumentViewer file={file} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="paper-container">
                        <DocumentList />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MyDocuments;





