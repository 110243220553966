import React, { useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import banner1 from "../../../assets/home/banner1.png";
import banner3 from "../../../assets/home/banner3.jpg";
import banner4 from "../../../assets/home/banner4.jpg";
import { Button } from "@mui/material";
import "./css/Hero.css";
import { useNavigate } from "react-router-dom";
import Contact from "./Contact";

const Hero = ({ handleContactScroll }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact-form");
  };

  return (
    <section>
      <Carousel infiniteLoop stopOnHover={false} autoPlay showThumbs={false}>
        <div style={{ height: "90vh", position: "relative" }}>
          <div className="hero-overlay"></div>
          <img src={banner1} alt="childrens" style={{ height: "90vh", zIndex: "5" }} />
          <div className="hero-content" style={{ marginTop: "30vh" }}>
            {/* <h1>Unleash the Power of Charity</h1>
            <p>
              Join hands with ACT to supercharge your donations and fundraising. Our extensive range of services
              provides you with the tools and support you need to streamline your donations and maximise your
              fundraising process.
            </p> */}
            <div className="button-container">
              <Button onClick={handleNavigate} variant="contained" size="large">
                Apply NOW
              </Button>
              <Button onClick={handleContactScroll} variant="outlined" size="large">
                CONTACT US
              </Button>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  );
};

export default Hero;
