import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRequestTopupMutation } from "../../../store";
import { toast } from "react-toastify";
import Loader from "../../shared/Loader";
import { Button } from "@mui/material";
import "./css/requestTopup.css";

const RequestTopUpComponent = () => {
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const balance = useSelector((state) => state.user.balance);

  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [moneyType, setMoneyType] = useState(client?.DefaultPayment || "Bank");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestTopup] = useRequestTopupMutation();

  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleNoteChange = (e) => setNote(e.target.value);
  const handleMoneyTypeChange = (e) => setMoneyType(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(amount) > balance) {
      toast.error("Insufficient balance.");
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await requestTopup({ clientID: client?.ID, moneyType, amount, note, token });
      if (res?.data) {
        toast.success(res.data.message);
        setAmount("");
        setNote("");
      } else {
        toast.error(res.error.data.message);
      }
    } catch (error) {
      toast.error("Error requesting top-up.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const topUpOptions = useMemo(() => {
    if (client?.CardStatus === "Active" || client?.CardStatus === "Card Ordered") {
      return (
        <>
          <option value="Bank">Bank Pay out</option>
          <option value="P.P. Card">Prepaid Card Top-Up</option>
        </>
      );
    }
    return <option value="Bank">Bank Pay out</option>;
  }, [client?.CardStatus]);

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div className="topup-wrapper">
      <div className="topup-container">
        <h2 className="topup-header">Request a Top-Up</h2>
        <form onSubmit={handleSubmit} className="form">
          <div className="topup-formGroup">
            <label className="topup-label">Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={handleAmountChange}
              className="topup-input"
              required
            />
          </div>
          <div className="topup-formGroup">
            <label className="topup-label">Pay out method:</label>
            <select
              value={moneyType}
              onChange={handleMoneyTypeChange}
              className="topup-select"
            >
              {topUpOptions}
            </select>
          </div>
          <div className="topup-formGroup">
            <label className="topup-label">Note:</label>
            <input
              type="text"
              value={note}
              onChange={handleNoteChange}
              className="topup-input"
            />
          </div>
          <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
            Submit
          </Button>
        </form>
        <div style={{ marginTop: "20px", textAlign: "center", color: "#555" }}>
          <p>Current Balance: £ {balance}</p>
        </div>
      </div>
    </div>
  );
};

export default RequestTopUpComponent;
